import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { RichText } from 'prismic-reactjs';
import Hero01 from '../components/index/01Hero';
import OurStory02 from '../components/index/02OurStory';
import Dishes03 from '../components/index/03Dishes';
import Quality04 from '../components/index/04Quality';
// import GiftBanner05 from '../components/index/05GiftBanner';
// import Press06 from '../components/index/06Press';
import MenuBanner07 from '../components/index/07MenuBanner';
import Location08 from '../components/index/08Location';
import Menu10 from '../components/index/10Menu';
import GalleryBanner09 from '../components/index/09GalleryBanner';
// import Menu10 from '../components/index/08Location';
// import GalleryBanner09 from '../components/index/09GalleryBanner';
// import Instagram10 from '../components/index/10Instagram';

const MenuPage = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        prismic {
          allHome_pages {
            edges {
              node {
                title
                description
                seo_image
                seo_imageSharp {
                  childImageSharp {
                    fixed(width: 1024) {
                      ...GatsbyImageSharpFixed_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { node } = data.prismic.allHome_pages.edges.slice(0, 1).pop();
      if (!node) {
        return null;
      }

      const { title, description, seo_imageSharp } = node;

      const meta = [];

      if (seo_imageSharp) {
        meta.push({
          name: `og:image`,
          content:
            data.site.siteMetadata.siteUrl +
            seo_imageSharp.childImageSharp.fixed.src,
        });
      }

      return (
        <Layout>
          <SEO
            title={title ? RichText.asText(title) : null}
            description={description ? RichText.asText(description) : null}
            meta={meta}
          />
          {/*<Hero01 />*/}
          <Menu10 />
          {/*<MenuBanner07 />*/}
          {/*<Location08 />*/}
          {/*<GiftBanner05 />*/}
          {/*<OurStory02 />*/}
          {/*<Dishes03 />*/}
          {/*<Quality04 />*/}
          {/*<Press06 />*/}
          {/*<GalleryBanner09 />*/}
          {/*<Instagram10 />*/}
        </Layout>
      );
    }}
  />
);

export default MenuPage;
